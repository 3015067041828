<template>
  <v-dialog v-model="input" persistent max-width="400">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-container>
        <v-layout row>
          <BaseButton
             text="Select File"
             @clicked="selectFile"
             color="accent"
             icon="cloud_upload"
          />

        </v-layout>
        <v-layout row>
          <input
            type="file"
            @change="onFilePicked"
            ref="fileInput"
            class="hideme"
            multiple
          >
          <p class="file-names">{{uploadedFileNames}}</p>
        </v-layout>
        <v-layout row>
        <div class="v-progress-linear">
        <v-progress-linear
          height="10"
          color="accent"
          :value="uploadProgress"
         >
         </v-progress-linear>
         </div>
        </v-layout>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" flat v-if="declineText"
          @click="clicked(false)">{{ declineText }}</v-btn>
        <v-btn color="accent" flat @click="clicked(true)">{{ confirmText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import filePicker from '@/components/mixins/filePicker';
import { mapGetters, mapActions } from 'vuex';
import baseEditMixin from '@/components/mixins/workspace/baseEditMixin';
import TenantUser from '@/models/TenantUser';

export default {
  name: 'BaseUploadModal',
  mixins: [filePicker, baseEditMixin],
  data: () => ({
    progressPercentage: 0,
    userNames: [],
    userModel: new TenantUser({}),
  }),
  props: {
    open: Boolean,
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: 'Upload',
    },
    declineText: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('users', ['getAll', 'getByKey', 'loaded', 'getCurrent', 'uploadProgress']),
    input: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    uploadedFileNames() {
      let names = '';

      if (this.fileData !== null) {
        Object.keys(this.fileData).forEach((i) => {
          names = names.concat(this.fileData[i].name).concat(',');
        });
      }
      return names.substring(0, names.length - 1);
    },
  },
  methods: {
    ...mapActions('users', {
      saveUser: 'save',
      bulkSave: 'bulkSave',
      setUploadProgress: 'setUploadProgress',
    }),
    clicked(accepted) {
      this.input = false;
      // Get file contents.
      if (accepted) {
        const vm = this;
        const reader = new FileReader();
        const file = this.$refs.fileInput.files[0];
        reader.readAsText(file, 'UTF-8');
        reader.onload = (evt) => {
          const text = evt.target.result;
          vm.userNames = text.split(',');
          // We now have an array of the user names, so we can save them,
          // However, because of the joys of REST, we have to do them one
          // at a time.
          vm.saveUsers(vm.userNames);
        };
      } else {
        this.$emit('uploadModalClicked', accepted);
      }
    },
    selectFile() {
      this.resetInput();
      const f = this.$refs.fileInput;
      f.click();
    },
    resetInput() {
      this.fileData = null;
      const input = this.$refs.fileInput;
      input.type = 'file';
    },
    async saveUsers(users) {
      const vm = this;
      const usersObj = {
        tenantCode: vm.getStickyTenant,
        users: [],
      };
      users.forEach((name) => {
        const uploadUser = new TenantUser({});
        // We have to manually set the email address since we are
        // not getting the value from a bound form field.
        uploadUser.userId = name;
        usersObj.users.push(uploadUser);
      });
      await this.bulkSave(usersObj);
      this.$emit('userUploadComplete');
    },
  },
  components: {
    BaseButton: () => import('@/components/base/BaseButton'),
  },
};
</script>

<style scoped>
  main {
    display: flex;
    align-items: flex-start;
    background-color: gray;
    padding: 10px;
    width: 300px;
    height: 300px;
  }

  .v-progress-linear {
    display: block;
    margin: 0 auto;
    width: 345px;
  }

  button.v-btn {
    margin-left: 18px;
  }

  .v-card__text {
    padding-left: 24px;
  }

  .v-card__title {
    padding-left: 24px;
  }

  .container {
    padding: 8px;
  }

  p.file-names {
    margin-left: 20px;
  }
</style>
